import axios from 'axios'
import store from 'src/store'

const request = axios.create()
let localServerPort = ''

/**
 * @description axios请求拦截器
 */
request.interceptors.request.use(
  async config => {
    if (!localServerPort && window.isInBitBrowser) {
      localServerPort = await window.bitClientMain.getLocalServerPort()
    }
    config.baseURL = `http://127.0.0.1:${localServerPort}`
    const userInfo = store.getters['user/userInfo']
    if (userInfo.isLocalToken) {
      config.headers['x-api-key'] = userInfo.localToken
    }

    return config
  },
  error => {
    return Promise.reject(error)
  }
)

request.interceptors.response.use(
  res => {
    if (res.data.success) return res.data.data
    return Promise.reject(res.data.msg)
  },
  error => {
    return Promise.reject(error)
  }
)

export function setLocalPort(port) {
  localServerPort = port
}

export function openPhoneBrowser(data) {
  return request.post(`phone/open`, data)
}

export function closePhoneBrowser(data) {
  return request.post(`phone/close`, data)
}

export async function closeAllPhoneBrowsers(data) {
  try {
    await request.post(`phone/close/all`, data)
  } catch (err) {}
}

export async function getAllPhoneBrowsersConfig(data) {
  try {
    return await request.post(`phone/opened/configs`, data)
  } catch (err) {}
}

export function setPhoneBrowserBoundsFlexable(data) {
  return request.post(`bounds/flexable`, data)
}

export function setPhoneBrowserBounds(data) {
  return request.post(`phone/bounds`, data)
}

export function saveimgs(data) {
  return request.post(`phone/saveimgs`, data)
}

// 获取打开的所有窗口
export function getOpenedPhones() {
  return request.post(`phone/opened/ids`)
}

export function reconnectPhoneBrowser(data) {
  return request({ url: '/phone/reconnect', method: 'post', data })
}

export function getGlobalConfigs() {
  return request({ url: '/configs/all', method: 'post' })
}

export function saveGlobalConfig(data) {
  return request({ url: '/configs/save', method: 'post', data })
}

export function listLocalDBLogs(data) {
  return request({ url: '/extralog/list', method: 'post', data })
}

export function deleteLocalDBLogs(ids = []) {
  return request({ url: '/extralog/delete', method: 'post', data: { ids } })
}

export function readexcel(data) {
  return request({ url: '/utils/readexcel', method: 'post', data })
}

export function getQunkongId(data) {
  return request({ url: '/phone/qunkong/ids', method: 'post', data })
}

export function closeQunkong(data) {
  return request.post(`/phone/qunkong/close`, data)
}
