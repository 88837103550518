export default {
  groupTab: 'Phone Profile Group',
  num: 'Group Profiles',
  creatEnv: 'Add',
  editEnv: 'Edit Profile',
  computingSet: 'Settings',
  computingSetTip1: 'Once the profile is created, the type cannot be changed.',
  computingSetTip0: 'Once the profile is created, the type cannot be changed.',
  computingSetChineTip0:
    'It can only be used normally within the mainland China network environment. Once created, it cannot be modified. Choose carefully!',
  computingSetChineTip1: 'It can only be used normally within the mainland China network environment.',
  openEnvCombo: 'Package',
  computingManage: ' Computing Power',
  batchUpdateProxy: 'Batch Update Proxy',
  closeEnvTitle: 'Close Profile',
  batchCloseEnv: 'Batch Close Profiles',
  editConfig: 'Edit Profile Configuration',
  openEnv: 'Opening Phone',
  envExpired: 'Subscription Expired',
  editNameWithSeq1: 'Update Profile Name (Seq: {seq})',
  batchOpenEnv: 'Batch Close Selected Phone',
  batchAllCloseEnv: 'Batch Close All Phone',
  todayPhone: 'Times',
  envName: 'Name',
  name: 'Name',
  envRmark: 'Profile Remarks',
  lastUpdateTime: 'Update Time',
  model: 'Model',
  createPeople: 'Creator',
  pkgEnvironment: 'Phone Profiles: {count}',
  buy: 'Purchase',
  openStatus: 'Open State',
  totalEnvironment: 'Profiles: {count}',
  pleaseSelect: 'Please select phone profile',
  customEnvName: 'Name the profile',
  nameTip: 'Name this profile for future daily management operations!',
  groupSelectTips:
    'Note: If you select a group under another account, the profile will be transferred to the group of other accounts (the ownership of the profile will belong to other accounts).',
  computeType0: `"Monthly Computing Power" will be used first. If no Monthly Computing Power is available, "Temporary Computing Power" will be used.`,
  computeType10: `Billing unit: {num}/15 minutes(capped at {maxNum}/day). Computing power is automatically released and billing stops on shutdown; billing continues during suspend.`,
  computeType11: `Billing standards are referenced below. Power usage will stop when the device is shut down, but will continue when suspended.`,

  computingBilling: '+ <b class="text-orange">{num}</b>/15 Minutes',
  computeType2:
    'Automatically bind available monthly computing power; one profile uses one monthly computing power. It can be unbound on shutdown for use by other profiles.',
  computeType20: 'Automatically bind available monthly computing power; one profile uses one monthly computing power.',
  computeType3:
    'Equipped with various sensors for a more realistic profile. Must be bound to Monthly Computing Power for use; the type can only be unbound after the profile expires.',
  remarkPlaceholder: 'Enter the profile remarks',
  ruleNameMsg: 'The profile name can have up to 30 characters',
  allPhoneClosed: 'All phone have been turned off',
  closePhone: 'The phone is closed.',
  checkedHasClosed: 'The selected phone is closed.',
  deleteTip: 'Once the profile is deleted, it cannot be recovered. Are you sure you want to permanently delete the profile?',
  batchDelect: 'Batch Delete Profiles',
  deleteCompletelybrowser: 'Permanently Delete Profile',
  computingStatus: 'Status',
  boundComputingTips: 'Shutdown and unbind Monthly computing power; the unbound Monthly computing power can be used by other Profiles.',
  releaseComputingTips: 'Shutdown and stop billing',
  billingProgress: 'Billing',
  closeEnv: 'Release computing power',
  billingProgressTip: 'Computing power in use, continuous billing',
  boundComTip: 'Bound monthly computing power: {name}',
  releaseComputing: 'Release computing power',
  closeEnvStopCharging: 'Click "Confirm" to release computing power and stop billing. Do you want to confirm the shutdown?',
  closeEnvUnbindComputing:
    'Click "Confirm" to unbind the Monthly computing power and shut down. The unbound Monthly computing power can be used by other Profiles. Do you want to confirm the shutdown?',
  closeEnvBtn2: 'Shutdown',
  closeEnvBtn1: 'Shutdown',
  temporarilyClosed: 'Suspend',
  temporarilyClosed1: 'Suspend',
  temporarilyClosedTip1:
    '<span class="text-negative">Shutdown:</span> Close the profile window and shut down the cloud phone. Unbind the currently bound Monthly computing power for use by other profiles!',
  temporarilyClosedTip2:
    "<span class='text-negative'>Shutdown:</span> Close the profile window and shut down the cloud phone. Release Temporary computing power and stop billing!",
  unbindComputing: 'Unbind computing power',
  closeEnvTip1: 'Shutdown and unbind Monthly computing power. The profile might start a bit slower when restarted after shutdown!',
  closeEnvTip2: 'Shutdown and release Temporary computing power. The profile might start a bit slower when restarted after shutdown!',
  notMultipleOpen: 'The current profile does not allow multiple accounts to be opened simultaneously.',
  searchNamePlaceholder: 'Enter profile name',
  temporarilyClosedTip1_1:
    '<span class="text-primary">Suspend:</span> Only close the profile window; Monthly computing power remains bound, and the cloud phone continues to run.',
  temporarilyClosedTip2_1:
    "<span class='text-primary'>Suspend:</span> Only close the profile window; Temporary computing power continues to be billed, and the phone profile continues to run!",
  changeConfirm: `
    <p class="text-red text-bold">After subscription changes:</p>
    <p class="text-red q-mt-sm">1. The system will not refund and effect immediately.</p>
    <p class="text-red q-mt-sm">2. The number of phone profiles and one-click new phone instances will change to match the new plan.</p>
    <p class="q-mt-sm">Confirm to change?</p>
    `,
  againOpen: 'The phone is already open by another user and cannot be opened again. Do you want to close this phone and reopen it?',
  envType: 'Type',
  envProxyTip: 'The phone profile must be configured with a valid proxy; otherwise, the phone profile cannot be accessed!',
  envChineProxyTip: 'The phone profile must be configured with a valid proxy; otherwise, the phone profile cannot be accessed!',
  selectComputing: 'Select Computing',
  languageTips: 'Generate the corresponding language based on IP. Customization available if not enabled.',
  billingMethod: 'Billing Method',
  seq: 'Seq',
  billingMethodTip:
    'Once the device is powered on, the first 15 minutes of computing power usage fee will be charged, and then {money} will be charged every 15 minutes. Billing stops when powered off, but will continue if suspended. Any time less than 15 minutes will be charged as 15 minutes.',

  udpLable: 'UDP Protocol',
  udpTip:
    'If you cannot connect to the network, uncheck UDP, which indicates that the network does not support UDP. By default, it is checked to protect your IP from being exposed.',
  createEnvTip: `The {name} profiles you purchased have reached the limit ({num}). To continue purchasing, you need to complete real-name authentication to lift this restriction.`,
  envChangeTip:
    'The number in the package represents the maximum number of cloud phone profiles you can create. Each cloud phone profile requires cloud computing power to run, which will incur additional cloud computing power costs. Please be aware!',
  maxEnvTip:
    'The total number of available profiles for the main account has been exceeded. Please purchase a profile package or upgrade to a larger profile package!',
  disabled: 'Disabled',
  disabledProfile: 'Disable profile',
  enableProfile: 'Enable profile',
  batchDisabledProfile: 'Disable profiles in bulk',
  batchEnableProfile: 'Enable profiles in bulk',
  createEnvMoneyTip: 'Insufficient balance and monthly computing power. Please top up or purchase more computing power.',
  networkError: 'Network Error...',
  apkFileError: 'Please Select a Correct APK Format File!',
  foreignNetworkTip:
    'The current profile is bound to the computing type: {regionName}, and is only supported for use with a global network environment!',
  domesticNetworkTip:
    'The current profile is bound to the computing power type: {regionName}, and can only be accessed with the local network set to mainland China.',
  createDomesticNetworkTip: 'Note: {arg} is only supported when the local network environment is set to mainland China.',
  createForeignNetworkTip: 'Note: {arg} only supports a local network environment outside of mainland China!',
  phoneUpgradeTip:
    'Dear user, Global B will undergo a system upgrade at 08:30 Beijing time on November 18th, which is expected to take 3 hours. During this period, Global B will be unavailable. We recommend shutting down all cloud phones using Global B. Thank you for your understanding and cooperation!',
  phoneUpgradeLoadingTip:
    'The data center will undergo a system upgrade on November 8 at 00:00 Beijing Time, which is expected to take 3 hours. Please be patient!',
  recoverProfile: 'Recover Profile',
  batchRecoverProfile: 'Recover profiles in bulk',
  openRootTip: 'ROOT privileges are enabled',
  appAuthenticationTip:
    'To regulate legal use of cloud phones, real-name authentication is required for installing apps in mainland China.',
  uploadFailed: 'Upload failed',
  copyEnvCody: 'Copy Code',
  environmentBilling: 'Profile Fee:',
  environmentBillingTip:
    'After creating a Cloud Phone, the first 24-hour fee will be charged immediately. Subsequently, a fee of {money} per environment will be charged every 24 hours. Charges will stop once the Cloud Phone is deleted. If the balance is insufficient, the Cloud Phone will be automatically deleted. Please ensure sufficient balance to avoid service interruption!',
  computingFee: 'Computing Power Fee:',
  switchEnvDaily: 'Time-Based Billing',
  switchEnvPackage: 'Plan-Based Billing',
  dayMaxFee: '(capped at {maxNum}/day)',
  created: '{num} Created',
  phoneTrialTitle: 'Claim Trial Credit',
  trialCreditBalance: '(Trial credit balance: <b class="text-orange">{money}</b>)',
  phoneTrialTitleTip: `Congratulations! You've received <b class="text-orange">{money}</b> Cloud Phone Trial Credit, which can be used to consume cloud phone profiles and temporary computing power. Enjoy the experience!`,
  claimNow: 'Claim Now',
  trialSuccessful: 'Success',
  energySavingLab1: 'Monthly billed cloud phone shuts down after',
  energySavingLab2: ' minutes of inactivity (not effective when suspended).',
  moneySavingLab: 'Pay-as-you-go cloud phone shuts down after 15 minutes of inactivity (not effective when suspended).',
  browserSetting: 'Browser Settings',
  phoneSetting: 'Phone Settings',
  batchImport: 'Batch Import',
  batchExport: 'Batch Export',
  excelMaxLimit: 'For a single Excel, the number of imported phone profiles should not exceed 500',
  importSupportsTip: '*It also supports importing common Phone Profiles.',
  batchImportMonth: 'It is expected to import {num} cloud phone environments, and the estimated cost is 2 ',
  batchUpdate: 'Batch Update',
  batchAciontTip1: 'First, export the phone profile that need to be updated.',
  batchAciontTip2: 'Modify the corresponding phone profile information based on the ID in the exported file.',
  batchAciontTip3: 'Upload the modified Excel file to the server.',
  batchAciontTip4:
    'Note: Do not delete or modify the ID in the Excel file, as it will cause the environment information to be unrecognized. If you do not want to update certain data, do not modify or delete it!',
  exportTem: 'Import Phone Template',
  linkAddress: 'Link Address:',
  linkPassword: 'Link Password:',
  linkPasswordText: 'Link Password',
  linkMethod: 'Link Method:',
  getAdbLink: 'Get ADB Link Address in Cloud Phone Environment',
  copyAdbLink: 'Copy ADB Link Address',
  batchEnableAdb: 'Batch Enable ADB ',
  batchDisableAdb: 'Batch Disable ADB ',
  enableAdb: 'Enable ADB',
  disableAdb: 'Disable ADB',
  synchronizer: 'Synchronizer',
  syncNotSupported:
    "The current client version does not support opening the phone synchronizer. Please upgrade to the latest client and try again. Click 'Find New Version' at the top to update.",
  syncRequirement: 'Please ensure that at least 2 profiles with the same type are open and belong to the same billing mode.',
  qunkongOpended: 'The synchronizer is using this profile. Please operate in the synchronizer.',
  synchronizerRequirement: 'Synchronizer is already enabled. Please disable it and then re-enable it.',
  isClosing: ' is closing',
  batchOpeningPhone: 'Batch opening cloud phones, please wait...',
  selectSameComputing: 'Please select profiles with the same computing power.',
  batchOpen: 'Batch Open Profiles',
  batchOpenTip: 'Note: After the popup is closed, any unopen profiles will not continue to open.',
  selectedProfile: 'Selected Profiles:',
  closeSelectedProfile: 'The selected profiles have been successfully shut down.',
  batchClosePhoneText: 'Confirm batch suspend or shutdown operations for the selected cloud phone profiles?',
  batchOpenSelect: 'Batch open selected profiles',
  batchCloseSelect: 'Batch close selected profiles',
  selectEnvironmentRun: 'The selected environment is currently running. Please shut it down before making the transfer.'
}
