export default {
  base: 'Основные',
  preference: 'обычные',
  preferenceTips: 'Создать шаблон окна (обычные настройки)',
  fingerprint: 'отпечатков',
  fingerprintTips: 'Создать шаблон окна (настройки отпечатков)',
  global: 'Глобальные настройки',
  openAtLogin: 'Открыть при запуске',
  openAtLoginTips: 'Если открытие при запуске не работает, пожалуйста, скачайте и установите последнюю версию программного обеспечения.',
  download: 'Скачать сейчас',
  cacheDir: 'Кэш браузера',
  changeCacheDir: 'Изменить каталог кэша',
  clearCache: 'Очистить весь локальный кэш',
  cacheClearRule: 'Введите целое число, большее 1',
  autoDeleteCache: 'Автоматическое удаление кэша (в днях)',
  days: 'дней',
  daysPlaceholder: 'Введите количество дней',
  autoDeleteTips: 'Кэш до заполненного количества дней будет удален автоматически. Без заполнения автоматического удаления не будет. ',
  detectAndDelete: 'Обнаружить и удалить',
  autoPasteDelay: 'Задержка авто-вставки',
  autoPastePlaceholder: 'Введите миллисекунды',
  autoPasteTips1: 'Подсказка: введите значение в миллисекундах. 1000 миллисекунд = 1 секунда.',
  autoPasteTips2:
    'Задержка в миллисекундах между вводом каждого символа при авто-вставке. Чем больше значение, тем больше задержка и дольше будет занимать ввод всей информации. Рекомендуется установить значение не более 500 миллисекунд!',
  autoPasteTips3:
    'Использование: на веб-странице в поле ввода нажмите сочетание клавиш {keys}, чтобы последовательно вставить содержимое буфера обмена с заданным временем задержки.',
  milliseconds: 'миллисекунды',
  batchOpenInterval: 'Интервал времени между пакетным открытием окон',
  batchOpenPlaceholder: 'Введите миллисекунды',
  batchOpenTips: 'Внимание: после закрытия всплывающего окна, остальные не будут открываться.',
  batchOpenRule: 'Введите целое число миллисекунд (>0)',
  badge: 'Настройка значка браузера',
  badgeEmpty: 'Не отображать значок',
  badgeBySeq: 'Отображать порядковый номер окна',
  badgeBySort: 'Отображать номер пользовательской сортировки (последние 5 цифр)',
  badgeByName: 'Отображать название окна (первые 3 символа)',
  badgeByRemark: 'Отображать комментарий окна (первые 3 символа)',
  badgeById: 'Отображать идентификатор окна (последние 5 символов)',
  performance: 'Включить мониторинг производительности',
  performanceTips: 'Проверка производительности при открытии окна, при недостатке остановить открытие окна',
  performanceValue: 'Предел производительности',
  minMemory: 'Максимальная память',
  minDiskSpace: 'Мало места на диске',
  performanceTips2: 'При достижении предела производительности мониторинга будет прекращено открытие окон браузера.',
  localApi: 'Local API',
  localApiPort: 'Local API Port',
  logs: 'Журнал системы',
  openDir: 'Открыть',
  netDetect: 'Проверка сетевой среды',
  detect: 'Проверить',
  saveBase: 'Сохранить базовые настройки',
  lumautoTitle: 'Настройки прокси Luminati',
  oxylabsautoTitle: 'Настройки прокси Oxylabs',
  iphtmlautoTitle: 'Настройки прокси IPHTML',
  ipideaautoTitle: 'Настройки прокси IPIDEA',
  ip2worldTitle: 'Настройки прокси IP2WORLD',
  smartTitle: 'Настройки прокси Smart',
  stormTitle: 'Настройки прокси Storm',
  swiftTitle: 'Настройки прокси Swift',
  lunaproxyTitle: 'Настройки прокси Lunaproxy',
  bookmarksTitle: 'Импорт закладок',
  ipflyTitle: 'Настройки прокси IPFLY',
  ipfoxyTitle: 'Настройки прокси IPFoxy',
  kookeeyTitle: 'Настройки прокси kookeey',
  '922proxyTitle': 'Настройки прокси 922s5 ',
  '922proxy': '922s5 Настройки прокси',
  rolaipTitle: 'Настройки прокси Rola-IP',
  socks5ioTitle: 'Настройки прокси Rsocks5.io',
  skyipTitle: 'Настройки прокси Sky-IP',
  freeSpaceRule1: 'Значение должно быть >= 1',
  freeSpaceRule2: 'Максимум 2 знака после запятой',
  sureToLeave: 'Настройки отпечатков не сохранены. Вы уверены, что хотите покинуть страницу?',
  preferenceLeave: '"Настройки окна не сохранены. Вы уверены, что хотите покинуть без сохранения?"  ',
  bookMarkLeave: 'Закладки Chrome не сохранены. Вы уверены, что хотите покинуть страницу?',
  changeDirConfirm:
    'Изменение директории кэша не приведет к автоматическому копированию файлов кэша в новую директорию. Если это необходимо, пожалуйста, скопируйте файлы вручную.<br/><br/>Текущая директория кэша: {dir}',
  clearCacheConfirm:
    'Эта функция удалит все кэшированные данные (если на этом компьютере вход выполнен с разных учетных записей браузера с разными отпечатками, то все данные кэша для всех учетных записей будут удалены)',
  clearCacheSuccess: 'Все данные кэша окон успешно очищены',
  saveFailed: 'Ошибка сохранения настроек. Пожалуйста, повторите попытку или попробуйте сохранить после перезапуска программы',
  upgradeTips: 'Эта функция доступна в последней версии клиентского приложения. Обновите его',
  bookmarksTips:
    'Подсказка: Выберите файл закладок Chrome для автоматического распознавания закладок. Все открытые окна браузера импортируют эти закладки. Функция синхронизации закладок должна быть включена в настройках окон браузера, чтобы это сработало!',
  bookmarksLabel: 'Выберите файл закладок в формате HTML, экспортированный из Chrome',
  bookmarksBar: 'Панель закладок',
  noBookmarks: 'Нет закладок',
  otherBookmarks: 'Другие закладки',
  bookmarksClearConfirm: 'Вы уверены, что хотите удалить все закладки?',
  autoComplete: 'Автозаполнение имени пользователя и пароля',
  autoCompleteTips:
    'Подсказка: Эта функция автоматически заполняет имя пользователя и пароль для указанного веб-сайта во всех окнах браузера!',
  editAutoComplete: 'Редактировать имя пользователя и пароль',
  addAutoComplete: 'Добавить имя пользователя и пароль',
  autoCompleteUrl: 'Веб-сайт',
  autoCompleteUsername: 'Имя пользователя',
  autoCompletePassword: 'Пароль',
  urlRule1: 'Введите URL веб-сайта',
  urlRule2: 'Введите корректный URL',
  usernameRule: 'Введите имя пользователя',
  passwordRule: 'Введите пароль',
  deleteAutoCompleteConfirm: 'Вы уверены, что хотите удалить пароль этого пользователя?',
  duplicatedAutoComplete: 'Такой пароль уже добавлен',
  add: 'Добавить',
  ipWhitelist: 'Черный список прокси-IP',
  ipWhitelistTips:
    'Подсказка: После добавления домена в черный список прокси-IP, соответствующий домен будет использовать локальную сеть вместо прокси-сети. Домен поддерживает шаблоны подстановки, например *.facebook.com',
  domainPlaceholder: 'Введите доменное имя без протокола, например: baidu.com',
  domain: 'Домен',
  ipBtns: 'Быстрая настройка для популярных сайтов (экономия трафика прокси-сети)',
  fbBtn: 'При открытии браузера используется локальная сеть для загрузки статических ресурсов Facebook',
  facebookBtn: 'Откройте facebook.com, используя локальную сеть',
  twitterBtn: 'Откройте x.com, используя локальную сеть',
  gooBtn: 'Откройте google.com, используя локальную сеть',
  domainRequired: 'Введите домен',
  noProtocol: 'Протокол (http | https) не требуется',
  domainRepeat: 'Этот домен уже добавлен',
  saveAndDownDir: 'Директория сохранения и загрузки файлов',
  saveAndDownDirTips:
    'После настройки файлы всех окон будут автоматически сохраняться и загружаться в папку с соответствующим номером окна в директории',
  selectDir: 'Выберите директорию',
  selectFileDir: 'Директория выбора и загрузки файлов',
  selectFileDirTips: 'После настройки, при выборе и загрузке файлов будет использоваться эта директория по умолчанию.',
  pageZoom: 'Масштабирование страницы',
  pageZoomTips: 'Глобальная настройка масштабирования страниц для всех окон',
  extensionSwitch: 'Отображение переключателя "Синхронизация данных расширений между окнами"',
  extensionSwitchTips:
    'При включении этой функции будет отображаться переключатель "Синхронизация данных расширений" в разделе "Общие настройки" окна!',
  closeSoftware: 'При закрытии программы',
  rememberChoose: 'Запомнить выбор',
  toTrayAtLaunch: 'Свернуть программу в трей после открытия',
  brwoserOpenForm: 'Способы открытия окна браузера',
  brwoserOpenFormTip: 'После установки все окна браузера будут открываться в соответствии с вашими настройками.',
  defaultPosition: 'Открыть в указанном положении',
  minimize: 'Минимизация панели задач',
  maximize: 'Максимизация экрана',
  enableGoogleTranslate: 'Поддержка Google Translate через китайский прокси-IP',
  enableGoogleTranslateTip:
    'После включения окно браузера с использованием китайского прокси-IP также сможет использовать Google Translate.',
  googleBtn: 'При открытии браузера используется локальная сеть для загрузки связанных с Google услуг',
  clearCloudCache: 'Очистить облачный кэш',
  clearCloudCacheConfirm:
    'После очистки облачного кэша, смены компьютера для входа в программу или после очистки локального кэша, вы не сможете получить доступ к какому-либо облачному кэшу. Вы уверены, что хотите продолжить очистку?',
  clearCloudExtensionsCache: 'Очистить кэш расширения в облаке',
  clearCloudExtensionsConfirm:
    "После очистки всего облачного кэша расширения, смены компьютера или очистки локального кэша синхронизация кэша данных расширения будет недоступна, и переключатель 'Синхронизация данных приложения расширения' для всех окон автоматически будет отключен. Вы уверены, что хотите продолжить?",
  cloudHasCache: 'Проверить, существует ли кэш расширения в облаке',
  cloudCacheTips1: 'Запрос отправлен, пожалуйста, подождите и повторите клик для просмотра.',
  cloudCacheTips2: 'Идет выполнение запроса, пожалуйста, будьте терпеливы.',
  cloudCacheTips3: 'Не существует',
  cloudCacheTips4: 'Существует',
  isExtensionCache: 'Существует ли облачный кэш данных расширения:',
  everBeenExtension: 'Были ли когда-либо данные кэша расширения:',
  deleteExtensionSearchTip:
    'Примечание: Если данные кэша расширения были удалены 26 и 27 августа, то невозможно будет запросить, существовали ли когда-либо данные кэша расширения.',
  bookmarksMode: 'Режим импорта закладок',
  mergeMode: 'Объединить',
  replaceMode: 'Заменить',
  mergeTips:
    'Открытые окна объединят и отобразят как существующие закладки, так и глобально импортированные закладки; например, если существуют закладки A и глобально импортированные закладки B, то открытое окно отобразит как закладки A, так и B одновременно.',
  replaceTips:
    'Открытое окно заменит существующие закладки и отобразит только глобально импортированные закладки; например, если в окне есть существующая закладка A и глобально установленная закладка B, то открытое окно отобразит только глобально установленную закладку B.',
  extractWaiting: 'Время ожидания после успешного извлечения IP',
  extractWaitingTips:
    'Большинство поставщиков IP-адресов не активируют IP-адрес сразу после его извлечения. Вам, возможно, потребуется немного подождать, прежде чем его можно будет использовать. Рекомендуется подождать как минимум 2 секунды (1 секунда = 1000 миллисекунд).',
  localAuthTips:
    'Примечание: После включения контроля аутентификации для локального API, при вызове API необходимо добавить в заголовки HTTP запроса поле X-API-KEY со значением токена, сгенерированного ниже, в противном случае порты API не будут доступны.',
  enableLocalAuth: 'Включить контроль аутентификации',
  enableAuthConfirm:
    'Вы уверены, что хотите включить контроль аутентификации для локального API? После включения вам потребуется настроить заголовки HTTP запроса, в противном случае вызовы API не будут работать.',
  disableAuthConfirm: 'Вы уверены, что хотите отключить контроль аутентификации для локального API?',
  resetTokenConfirm: 'Вы уверены, что хотите сбросить токен? После сброса старый токен будет аннулирован и не сможет быть использован.',
  sysProxy: 'Настройка системного прокси',
  sysProxyTips: '',
  sysProxyServer: 'Прокси-сервер',
  sysProxyOpt1: 'Автоматическое обнаружение настройки системного прокси',
  sysProxyOpt2: 'Не использовать настройку системного прокси',
  sysProxyOpt3: 'Настроить порт системного прокси',
  sysProxyPort: 'Пользовательский порт',
  positionTips: 'По умолчанию в верхнем левом углу (x: 0, y: 0).',
  detectSuccess: 'Успешное обнаружение: ',
  detectFailed: 'Не удалось обнаружить',
  processProtect: 'Защита процесса',
  processProtectTips:
    'Примечание: При выполнении сценария, если обнаружены остаточные процессы, можно настроить следующие параметры для регулярной очистки остаточных процессов. 0 для отключения этой функции.',
  processProtectPeriod: 'Интервал (минуты)',
  pressShortcut: 'Пожалуйста, нажмите комбинацию клавиш.',
  multiKeys: 'Ярлык должен состоять из комбинации функциональной клавиши и буквенной клавиши.',
  shortcutKeysStart: 'Комбинация клавиш должна начинаться с {keys}.',
  noBothCtrlAndAlt: 'Комбинация клавиш не может одновременно содержать клавиши Ctrl и Alt/Option.',
  setShortcutSuccess: 'Комбинация клавиш успешно установлена!',
  setShortcutFailed: 'Не удалось установить комбинацию клавиш, обновите страницу и попробуйте снова!',
  shortcutKeys: 'авто-вставке Комбинации клавиш',
  promptForDownload: 'Всегда указывать место для скачивания',
  fileSaveAndSelect: 'Загрузка И Выбор Файла',
  addFolder: 'Добавить папку закладок',
  addBookmark: 'Добавить закладку',
  pleaseName: 'Пожалуйста, укажите имя',
  bookmarkExists: 'Имя уже существует',
  urlExists: 'URL уже существует',
  isDeleteBookeTip: 'Закладка будет удалена. Вы хотите продолжить?',
  folder: 'папку закладок',
  bookmark: ' закладку',
  resetTokenSuccess: 'Токен успешно сброшен'
}
