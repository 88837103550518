export default {
  inviteUrl: 'Your BitBrowser+Cloud Phone Referral Link',
  qrCodeName: '{name} Promotional QR Code',
  generateQrcode: 'Generate QR Code',
  generateQrcodeTip: 'Use your promotional QR code to refer users to the product for rewards.',
  inviteUrlTips: '『Please subscribe first to permanently display your promotion links.』',
  copyUrl: 'Copy',
  inviteCode: 'Your Referral Code',
  inviteTips: '『Show after becoming a promoter』',
  copyCode: 'Copy',
  overview: 'Summary',
  couponBtn: 'Coupon',
  withdraw: 'Withdraw',
  detailsBtn: 'Details',
  withdrawRecords: 'Withdrawal Record',
  inviteUsers: 'Invited Users',
  userUnit: '',
  rewards: 'Rewards',
  pleaseCreatedTime: 'Enter select registration time',
  pleaseTradeTime: 'Please select the transaction time',
  moneyUnit: '¥',
  withdrawTotal: 'Withdrawn',
  withdrawMoney: 'Withdrawable Amount',
  policyText1: 'The {name} invitation reward mechanism has been launched, and the rewards are generous!',
  policyText2: 'By inviting rewards, you can not only recommend {name} to those who need it, but also earn a generous share for yourself!',
  policyTitle: 'Rules:',
  policy1: '1. Open to all {name} subscription users!',
  policy2:
    '2. Copy your referral link, and promote it in the way you are good at. By registering users and spending through your referral link, you can get a high percentage of the amount spent.',
  policy3Label: 'Rewards: ',
  policy3:
    'User browser package consumption is divided by <strong>{rate}%<strong>, and cloud phone consumption is divided by <strong>{rate1}%<strong>!',
  policy3Tips: '(Permanent passive income, unlimited term)',
  policy4Label: 'Bonus: ',
  policy4:
    'Invite 5 users in total, and give a ¥50 coupon; Invite 10 users in total, and give another ¥50 coupon; Invite 20 users in total, and give another ¥100 coupon.',
  policy51: 'Withdrawable amount should greater than ',
  policy52: ', and at least',
  policy53: 'invited users subscribe the paid package, then you can apply for withdrawal.',
  policy6:
    'If your invited user cancels the subscription or generates a refund during the award period, the corresponding invitation award will also be cancelled.',
  policy7:
    'Do not participate in this activity by any improper means/fraudulent behaviour, if there are violations, the official will freeze all rewards and pursue legal responsibility.',
  policy7_1: `The same device/IP/payment account generates the associated subordinate user, which permanently stops the commission sharing to the superior!`,
  policy8:
    'The final interpretation of this activity belongs to the organiser, if you have any questions, please contact customer service!',
  policy9:
    'Note: Subordinate users associated with the same device/IP/payment account will permanently stop commission payments to their superiors!',
  copySuccess: 'Success',
  withdrawMsg: `Withdrawable amount should greater than <span class='text-red'>{symbol} {money}</span>!`,
  withdrawMsg2: `At least {min} invited users are required to have subscribed the paid packages, currently only {max}!`,
  detailsDialog: 'Details',
  detailTips: 'You are the general agent, and you can set the proportion of lower level users!',
  rewardsTips:
    'Your commission rates: {rate}% for sub-level browser package consumption and {rate1}% for cloud phone consumption. You earn corresponding commissions when your sub-level users make direct buy!',
  rewardsTips2:
    'If your invited user invite another user, he will get a commission share ratio equal to the share ratio you set, and you will get a rebate of: payment amount × (your share ratio - lower-level user share ratio)!',
  users: 'Users',
  records: 'Records',
  invitedUsers: 'Invited Users: ',
  totalPay: 'Paid: ',
  totalRewards: 'Rewards: ',
  createdTimeStart: 'Registration Start Date',
  createdTimeEnd: 'Registration End Date',
  tradeTimeStart: 'Trading Start Date',
  tradeTimeEnd: 'Trading End Date',
  minRate: 'Cannot be lower than the system default proportion.',
  maxRate: 'Cannot greater than your proportion',
  username: 'Username',
  invitedBy: 'Referred',
  rewardsRate: 'Bonus Rate(Browser Profiles)',
  phoneRewardsRate: 'Bonus Rate(Phone Profiles)',
  totalPayMoney: 'Consumption Amount',
  createdTime: 'Created Time',
  remark: 'Remark',
  payMoney: 'Amount',
  percent: 'Rate',
  reward: 'Reward',
  tradeTime: 'Trade Time',
  saveSuccess: 'Saved',
  status0: 'Under Review',
  status1: 'Paid',
  status2: 'Reject',
  alipay: 'Alipay: ',
  bank: 'Bank Card: ',
  balance: 'Balance',
  requestTime: 'Request Time',
  requestMoney: 'Amount',
  payInfo: 'Infomations',
  status: 'Status',
  paypal: 'PayPal:'
}
