export default {
  seq: '序号',
  activate: '去开通',
  renew: '去续费',
  add: '创建窗口',
  editBrowser: '编辑窗口',
  import: '批量导入窗口',
  batchAdd: '批量创建窗口',
  batchUpdate: '批量更新窗口信息',
  used: '已用',
  total: '总数',
  pkgBrowsers: '套餐窗口数：',
  pkgEnvs: '套餐手机环境数：',
  pkgUsedEnvs: '已创建手机环境数：',
  pkgAssignedEnv: '已授权手机环境数：',
  pkgDisabledEnv: '被禁用员工创建手机环境数：',
  pkgUsed: '已创建窗口数：',
  pkgAssigned: '已授权窗口数：',
  pkgDisabled: '被禁用员工创建窗口数：',
  openCount: '今日打开',
  activatePkg: '开通套餐',
  changePkg: '变更套餐',
  placeholder1: '选择员工账号搜索',
  all: '全部',
  mine: '自建',
  commonly: '常用',
  allStatus: '全部',
  opened: '已打开',
  unOpened: '未打开',
  share2me: '分享',
  transform2me: '转移',
  group: '分组',
  groups: '查看分组',
  noGroup: '未分组',
  checked: '已选：',
  refreshData: '刷新数据',
  customizeColumns: '自定义显示列',
  recycle: '回收站',
  moreAction: '更多操作',
  openChecked: '打开选中窗口',
  clone: '克隆指定窗口',
  setCommonly: '将选中窗口设为常用',
  cancelCommonly: '将选中窗口取消常用',
  export: '批量导出窗口',
  exportChecked: '导出选中窗口',
  exportBySeq: '导出指定序号范围窗口',
  exportByGroup: '导出指定分组窗口',
  batchEdit: '批量修改窗口',
  updateGroupOfChecked: '修改选中窗口分组',
  updateProxyOfChecked: '修改选中窗口代理',
  updateRemarkOfChecked: '修改选中窗口备注',
  updateSortOfChecked: '修改选中窗口自定义序号',
  updatePropertiesOfChecked: '修改选中窗口所有配置',
  closeBrowsers: '批量关闭窗口',
  closeChecked: '批量关闭选中窗口',
  closeAll: '批量关闭所有窗口',
  clearCaches: '清空窗口缓存',
  clearCachesAll: '清空窗口缓存（全部缓存）',
  share: '分享选中窗口与他人共用',
  shareBrowser: '分享窗口与他人共用',
  transform: '转移选中窗口给他人',
  transformBrowser: '转移窗口给他人',
  removeShared: '删除选中分享窗口',
  deleteBrowsers: '删除选中窗口',
  deleteBrowser: '删除窗口',
  deleteCompletely: '彻底删除选中窗口',
  deleteCompletelybrowser: '彻底删除窗口',
  moveToRecycle: '删除选中窗口至回收站',
  moveBrowserToRecycle: '删除窗口至回收站',
  belongTo: '归属：',
  noProxy: '直连模式',
  remark: '备注',
  lastOpenTime: '最近打开时间：',
  lastCloseTime: '最近关闭时间：',
  lastUpdateTime: '最近修改时间：',
  userName: '用户名：',
  isOpening: '正在打开该窗口！',
  browserDisabled: '可用窗口数减少，窗口自动失效',
  opening: '打开中',
  open: '打开',
  goDown: '跳到底端',
  goUp: '跳到顶端',
  goMiddle: '跳到中间',
  editGroup: '修改分组',
  editGroupWithSeq: '修改分组（序号：{seq}）',
  editNameWithSeq: '修改窗口名称（序号：{seq}）',
  editNameWithSeq1: '修改云手机名称（序号：{seq}）',
  browserName: '窗口名称',
  editRemark: '修改备注',
  editRemarkWithSeq: '修改备注（序号：{seq}）',
  editRemarkType: '修改方式',
  replaceRemark: '替换',
  appendRemark: '追加',
  appendRemarkTips: '注：使用“追加”，则在原有备注基础上，换行去追加新内容',
  remarkContent: '备注内容',
  exportFromSeq: '起始序号',
  minSeqPlaceholder: '请输入大于0的数字',
  exportToSeq: '结束序号',
  maxSeqPlaceholder: '请输入大于起始序号的数字',
  shareTitle: '分享窗口',
  shareTitle1: '分享云手机',
  shareTips:
    '可以分享窗口给多个用户共同使用，输入用户名后点击“添加”按钮即可。若分享后出现部分平台无法评论等现象，则开启同步IndexedDB、同步Local Storage后重试。',
  shareTips1: '可以分享云手机环境给多个用户共同使用，输入用户名后点击“添加”按钮即可。',
  shareWith: '分享给用户',
  shareAdd: '添加',
  shareRemark: '分享备注',
  transformTitle: '转移窗口',
  transformTitle1: '转移云手机',
  transformTips:
    '转移窗口后，窗口将完全归属于被转移用户，您将不可再看到该窗口，请谨慎操作！若转移后出现部分平台无法评论等现象，则开启同步IndexedDB、同步Local Storage后重试。',
  transformTips1: '转移手机环境后，手机环境将完全归属于被转移用户，您将不可再看到该环境，请谨慎操作！',
  transformTo: '转移给用户',
  groupName: '分组名称',
  checkAll: '勾选全部',
  loading: '加载中...',
  checkGroup: '请选择分组',
  ruleNameMsg: '窗口名称不能超过50个字符',
  ruleRemarkMsg: '备注不能超过500个字符',
  ruleMinSeqMsg: '起始序号大于0',
  ruleMaxSeqMsg: '结束序号大于起始序号',
  usernameMinLength: '用户名最少6位',
  usernameExists: '已添加此用户名',
  remarkMaxLength: '备注最多100个字',
  transformGroupMsg: '请输入转移给用户名下的分组名称',
  noAddPermission: '您当前无创建窗口权限，请联系账号管理员开通',
  setSuccess: '设置成功',
  notInClient: '请安装客户端软件，在客户端软件中打开窗口！',
  installText: '安装客户端',
  getExtensionFailed: '获取扩展出错，请检查网络',
  getConfigFailed: '获取配置失败，请尝试刷新重试',
  openAnyway:
    '浏览器窗口已在其它账号打开，是否要在本账号下重复打开？<p style="margin-top: 10px">注意：重复打开后，不同账号关闭窗口后都会触发同步操作，可能造成各自同步不同的数据！</p>',
  cloneMsg: '克隆窗口，只允许选择一个窗口进行克隆，禁止多选！',
  cloneBrowser: '克隆窗口',
  openAnyway2: '该浏览器窗口在其他账号下已被同步为关闭状态，是否在本账号下重新打开？',
  openAnyway3: '本次不再提示',
  selectBrowser: '请选择窗口',
  noBrowserChecked: '未选择窗口',
  freeAccountTips: '该功能，免费套餐会有一定限制，收费套餐支持无限制使用。如需无限制使用，请开通/续费收费套餐后即可使用！',
  close: '关闭',
  checkedIncludesOpened: '所选窗口中包含已打开的窗口，请先关闭窗口后再进行操作',
  checkedIncludesOpenedPhone: '所选云手机中包含已打开的云手机，请先关闭云手机后再进行操作',
  checkedIncludesOpenedEnv: '所选环境中包含已打开的环境，请先关闭环境后再进行操作',
  editShareGroupMsg: '分享的窗口不可修改分组',
  editNameSuccess: '窗口名称修改成功',
  editRemarkSuccess: '备注修改成功',
  deleteConfirmMsg: '确认删除所选窗口吗？<br/>删除后可在窗口回收站中恢复！',
  moveToRecycleSuccess: '窗口回收成功',
  deleteCompletelyConfirm: '<p class="text-red">窗口彻底删除后不可恢复，确认要彻底删除窗口吗？</p>',
  deleteCheckPwd: `<p class="text-bold q-mb-sm">如需关闭密码验证，请到<u class="cursor-pointer">个人中心</u>关闭！</p>`,
  deleteCompletelySuccess: '删除成功',
  deleteShareConfirm: '确认删除分享窗口吗？',
  deleteShareSuccess: '删除分享成功',
  handleInClient: '请在客户端中操作',
  clearCachesConfirm: '清空浏览器缓存会导致浏览器相关登录状态等全部失效。<br/>确定要清空吗？',
  clearCacheSuccess: '删除成功',
  checkedHasClosed: '所选窗口已关闭',
  closeBrowser: '窗口已关闭',
  allBrowserClosed: '已关闭所有窗口',
  transformSuccess: '窗口转移成功',
  transformSuccess1: '云手机转移成功',
  atleatShareOneUser: '至少填写并添加一个用户',
  shareSuccess: '分享成功',
  exportConfirm: '确定要导出选中项吗？',
  exportFilename: '导出窗口.xlsx',
  exportBrowser: '导出窗口',
  browserHasOpened: ' 浏览器已打开',
  exportFilenameWithSeq: '导出窗口{seq}.xlsx',
  batchOpenTitle: '批量打开浏览器',
  batchOpenChecked: '已选窗口：',
  batchOpened: '已打开：',
  batchOpenFailed: '打开失败：',
  toSetting: '点此解决',
  batchOpening: '正在打开...',
  batchOpenTips: '注意：弹窗关闭后，未打开窗口将不再继续打开',
  CPU使用率: 'CPU使用率',
  内存使用率: '内存使用率',
  缓存所在目录剩余空间小于: '缓存所在目录剩余空间小于',
  extractIpFailed: '代理IP提取失败，停止打开',
  batchUpdateDialog: '批量更新窗口信息',
  batchUpdateImport: '单个Excel文件，导入窗口个数不要大于1000个',
  batchUpdateComment: '操作说明：',
  batchUpdateComments1: '首先导出需要更新信息的窗口；',
  batchUpdateComments2: '根据导出文件中的ID，修改对应的窗口信息；',
  batchUpdateComments3: '对于不希望更新，保持原有数据的字段，比如cookie，在对应单元格内填写 “Skip”即可；',
  batchUpdateComments4: '将修改后的Excel文件上传至服务器。',
  batchUpdateTips: '注意：不要删除或修改Excel文件中的ID，会导致识别不出窗口信息，不想更新的数据，请勿改动或删除！',
  proxyIp: '代理IP',
  createdTime: '创建时间',
  config: '配置',
  fileImport: '文件导入',
  importExcel: '导入Excel',
  downloadTemplate: '下载导入模板',
  importTips: '*也支持导入市面常见指纹浏览器的导入文件',
  downTemplateTips: '温馨提示',
  downTemplateTips2: '导入模板已更新，请下载最新模板使用！',
  checkFileErrMsg: '请选择文件',
  downloadFilename: '导入窗口模板.xlsx',
  uploadMaxSize: '上传文件大小不能超过20MB！',
  openProgressDialog: '浏览器正在打开...',
  browserOpened: '窗口已打开',
  searchItem: '搜索项目',
  setDefault: '设默认',
  searchNamePlaceholder: '输入窗口名搜索',
  searchUsernamePlaceholder: '输入用户名搜索',
  searchSeqPlaceholder: '按指定序号搜索（多个序号使用逗号分隔）',
  setSeq: '指定序号',
  minSeq: '最小序号',
  maxSeq: '最大序号',
  searchRemarkPlaceholder: '输入备注搜索',
  searchRemark: '窗口备注',
  searchProxyPlaceholder: '选择代理类型搜索',
  searchHostPlaceholder: '输入代理主机搜索',
  searchIdPlaceholder: '输入ID搜索',
  searchBrowserStatus: '窗口打开状态',
  seqRuleMsg: '请输入0 - 999999的数字',
  seqSplitRuleMsg: '请输入英文逗号分隔的整数序号',
  proxyRuleMsg: '请选择合法的代理类型',
  hostRuleMsg: '代理主机最多100个字符',
  ipRuleMsg: 'IP地址最多50个字符',
  shareBtn: '分享管理',
  shareBtn1: '分享管理',
  shareDialog: '我的分享管理',
  shareBrowserNamePlaceholder: '输入窗口名称搜索',
  sharePhoneNamePlaceholder: '输入设备名称搜索',
  shareRemarkPlaceholder: '输入备注搜索',
  shareUsernamePlaceholder: '输入分享用户名搜索',
  shareHandlerUser: '操作用户',
  shareCancel: '取消分享',
  shareCancelTips: '您确定要取消此窗口的分享吗？<br/>取消分享后，被分享的用户将对此窗口不再可见。',
  shareCancelTips1: '您确定要取消此手机环境的分享吗？<br/>取消分享后，被分享的用户将对此手机环境不再可见。',
  shareCancelSuccess: '取消分享成功',
  检查并同步扩展中: '检查并同步扩展中...',
  '扩展同步失败，请尝试重新启动': '扩展同步失败，请尝试重新启动...',
  '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！': '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！',
  开始获取IP: '开始获取IP...',
  '直连模式，网络畅通': '直连模式，网络畅通...',
  IP代理成功: 'IP代理成功...',
  网络不通已停止打开浏览器: '网络不通已停止打开浏览器...',
  正在同步指纹信息: '正在同步指纹信息...',
  正在同步缓存配置: '正在同步缓存配置...',
  指纹同步失败: '指纹同步失败',
  '浏览器缓存文件创建失败，请重试': '浏览器缓存文件创建失败，请重试',
  正在打开浏览器窗口: '正在打开浏览器窗口...',
  '正在更新浏览器内核，请稍后': '正在更新浏览器内核，更新完毕后会自动打开窗口！（若下载速度比较慢，请关闭代理节点再试）',
  打开窗口发生异常:
    '打开窗口发生异常：1. 检查软件标题下方是否显示橙色内核升级提示按钮，升级内核后重试；2. 检查窗口是否使用过久（超过1个月），尝试备份重要数据后清理缓存，再次打开；3. 可能系统时间不准确造成，请调整为准确时间；4. 本地网络不稳，切换线路重试，或有开启本地代理软件的，切换代理节点或临时关闭代理后重试；5. 以上方法尝试后问题还未解决，请联系客服！',
  缓存所在目录剩余空间小于: '窗口 {arg1} 启动失败，缓存所在目录剩余空间小于 {arg2}',
  内存使用率超出: '窗口 {arg1} 启动失败，内存使用率超出 {arg2}',
  同步IndexedDB失败: '同步IndexedDB失败',
  '同步Local Storage失败': '同步Local Storage失败',
  同步书签失败: '同步书签失败',
  同步历史记录失败: '同步历史记录失败',
  同步已保存的密码失败: '同步已保存的密码失败',
  同步Google账号信息失败: '同步Google账号信息失败',
  代理IP无法连接: '代理IP无法连接...',
  窗口已打开: '窗口已打开',
  '内核更新失败，请重新启动客户端再打开': '内核更新失败，请重新启动客户端再打开',
  正在加载代理IP: '正在加载代理IP',
  exportCookies: '批量导出Cookie',
  exportBrowserCookies: '导出Cookie',
  exportSelectedCookies: '导出选中窗口Cookie',
  exportCookiesBySeq: '导出指定序号范围窗口的Cookie',
  exportCookiesByGroup: '导出指定分组窗口的Cookie',
  exportCookiesConfirm: '确认以文本方式导出选中窗口的Cookie吗？',
  importCookies: '批量更新窗口Cookie',
  importCookiesTips: '注意：',
  importCookiesTips1: '每个浏览器Cookie必须单独使用一个txt文件，以窗口序号命名。比如窗口序号为：123，则命名为：123.txt；',
  importCookiesTips2: '所有txt文件放至同一个文件夹下，文件夹命名为：Cookies，然后压缩为zip文件；',
  importCookiesTips3: '一次最多更新100个txt格式（窗口）的Cookie，zip文件大小不能超过20MB；',
  importCookiesTips4: '上传成功后，对应序号窗口的Cookie将会替换更新为您上传的最新Cookie。',
  notZip: '请选择 Zip 文件上传。',
  importCookiesUploader: 'ZIP压缩包中包含txt文件不超过100个',
  updateCookiesSuccess: 'Cookie更新成功',
  reopenAtPos: '批量重启窗口',
  reopenSelectedAtPos: '批量重启选中窗口',
  reopenAllAtPos: '批量重启所有窗口',
  reopening: '正在重启窗口',
  reopeningBrowser: '重启窗口',
  reopenFinished: '窗口重启完成',
  reopenErrors: '以下窗口未能成功重启：',
  reopeningAndWait: '窗口正在重启中，请耐心等待...',
  nameSortTips:
    '如果窗口名称使用数字命名，并且想完全按照数字大小排序，请从比较大的数字开始（所有窗口名称的位数需要保持一致，样例：10000、10001、10099、10100、10101、10110），以确保可以完全按照数字大小排序，否则可能出现100、110排在10和99之间的类似情况！',
  nameSortTips1:
    '如果设备名称使用数字命名，并且想完全按照数字大小排序，请从比较大的数字开始（所有设备名称的位数需要保持一致，样例：10000、10001、10099、10100、10101、10110），以确保可以完全按照数字大小排序，否则可能出现100、110排在10和99之间的类似情况！',

  restoreSuccess: '还原成功',
  noBackupCookie: '未备份此窗口Cookie，无需还原',
  noShareSelf: '不能分享给自己，请重新输入用户名',
  batchBackupCookie: '批量备份Cookie',
  batchBackupCookieTip: '您将备份当前所选窗口的Cookie至服务器，是否继续操作？',
  correctUsername: '请输入正确的用户名',
  IP变更停止打开: '当前IP与上次打开窗口IP不一致，停止打开浏览器窗口...',
  上次打开窗口IP: '上次打开窗口IP：{arg1}',
  当前打开窗口IP: '当前打开窗口IP：{arg1}',
  '当前网络故障，请检查网络': '当前网络故障，请检查网络...',
  ipChangeAttention: '注意：如果您想打开该浏览器窗口，请到【编辑窗口->常用设置】中，关闭“IP发生变化停止打开”开关',
  clearCacheWithoutExtensions: '清空窗口缓存（保留扩展数据）',
  clearCachesConfirm2: '此操作会清空除了窗口扩展及扩展数据外的所有缓存文件，确定清空吗？',
  clearCloudCache: '删除云端扩展数据缓存',
  clearCloudCacheConfirm:
    '删除云端扩展数据缓存后，更换电脑登录软件将无法同步扩展数据缓存，且会自动关闭窗口的“同步扩展应用数据”开关，确定删除吗？',
  closeAllBrowser: '请关闭窗口后再操作',
  waitBatchOpenBrowser: '正在批量打开窗口，请稍等...',
  beginning: '开头',
  contain: '包含',
  precise: '精准',
  stopWhileCountryChange: '当前IP对应国家/地区与上一次打开时不一致，停止打开浏览器窗口...',
  lastCountryName: '上次打开窗口IP对应国家/地区：{arg1}',
  currentCountryName: '当前打开窗口IP对应国家/地区：{arg1}',
  countryChangeAttention: '注意：如果您想打开该浏览器窗口，请到【编辑窗口->常用设置】中，关闭“IP对应国家/地区发生变化停止打开”开关',
  clientTooOld: '您的客户端版本过低，请升级至最新版客户端后使用，功能健全，指纹更安全。',
  downloadLatestClient: '下载最新版',
  action: '操作',
  transferCancelTips: '确定将所选的窗口，转移给当前所分享的用户？转移后，窗口将完全归属于被转移用户，您将不可再看到该窗口，请谨慎操作！',
  transferCancelTips1:
    '确定将所选的云手机，转移给当前所分享的用户？转移后，云手机将完全归属于被转移用户，您将不可再看到该云手机，请谨慎操作！',
  transformSelect: '转移所选',
  sharePeople: '分享人：',
  selectUserPlaceholder: '请选择用户',
  updateConfig: '修改窗口配置',
  coreProductTip: '请选择相同浏览器内核的窗口进行批量修改。',
  isMacOpenBrowserTips: 'Mac系统暂不支持打开由火狐内核创建的窗口，请使用谷歌内核',
  repairProduct: '点此尝试修复',
  修复失败: '修复失败，如有开启代理软件，请关闭后重试！',
  修复成功: '修复成功，请重新打开窗口！',
  customSort: '自定义排序',
  editCustomSort: '修改排序',
  sortLabel: '排序',
  customSortBtn: '一键随机自定义排序',
  customSortDialogTip1: '请先到“自定义显示列”中，勾选“自定义排序”，才可以使用该功能。',
  customSortDialogTip2: '{msg}会覆盖原有的排序值，并无法找回，是否要继续修改？',
  sortOkBtn: '去设置',
  sortRuleMsg2: '排序数字必须大于等于0且小于3000000',
  customSeq: '自定义排序',
  seqRang: '序号范围',
  seqMultiple: '序号',
  specifySeq: '指定自定义排序',
  customSeqRange: '自定义排序范围',
  customSeqMsg: '随机自定义排序',
  positiveSequenceNum: '按正序生成序号',
  reverseSequenceNum: '按倒序生成序号',
  startSerialNumlabel: '起始序号',
  sequenceNumplaceholder: '请输入序号',
  positiveSequenceTip: '所选的窗口按设置的起始序号正序依次生成序号, 会覆盖原有的排序值，并无法找回！',
  reverseSequenceTip: '所选的窗口按设置的起始序号倒序依次生成序号, 会覆盖原有的排序值，并无法找回！',
  exportSelected: '导出选中窗口',
  verifyNow: '去验证',
  batchOpenComplateFlag: '执行完毕后保留此弹窗',
  cookieRobot: 'Cookie机器人',
  cookieRobotTip1:
    '针对刚新建的窗口，部分网站打开后可能会被认为可疑，需要有一定的访问记录，用户可以使用此Cookie机器人自动浏览目标网站，以产生访问记录。',
  cookieRobotTip2: '默认打开所选窗口的平台网址，也可自定义添加所需网址。',
  closeAfterRobotDone: '执行完关闭窗口',
  cookieRobotUrlValid: '请提供至少一个网址URL',
  delBackup: '删除备份',
  searchIdRule: '请输入正确的ID'
}
