export default {
  seq: '№',
  activate: 'Активировать',
  renew: 'Продлить',
  add: 'Создать окно',
  editBrowser: 'Редактировать окно',
  import: 'Окно пакетного импорта',
  batchAdd: 'Пакетное создание окон',
  batchUpdate: 'Пакетное обновление данных окон',
  used: 'Использовано',
  total: 'Всего',
  pkgBrowsers: 'Количество окон в пакете:',
  pkgUsed: 'Количество созданных окон:',
  pkgAssigned: 'Количество разрешенных окон:',
  pkgDisabled: 'Количество отключенных окон:',
  pkgEnvs: 'Количество окон в пакете: ',
  pkgUsedEnvs: 'Количество созданных мобильных сред: ',
  pkgAssignedEnv: 'Количество разрешенных сред:',
  pkgDisabledEnv: 'Количество отключенных сред: ',
  openCount: 'Сегодня',
  all: 'Все',
  activatePkg: 'Оплатить',
  changePkg: 'Изменить',
  placeholder1: 'Поиск сотрудников',
  mine: 'Собственные',
  commonly: 'Избранные',

  allStatus: 'Все',
  opened: 'Открыто',
  unOpened: 'Не открыто',
  share2me: 'Поделиться',
  transform2me: 'Перенести',
  group: 'Группа',
  groups: 'Просмотр групп',
  noGroup: 'Без группы',
  checked: 'Выбрано: ',
  refreshData: 'Обновить данные',
  customizeColumns: 'Настроить отображаемые столбцы',
  recycle: 'Корзина',
  moreAction: 'Больше',
  openChecked: 'Открыть выбранное окно',
  clone: 'Клонировать указанное окно',
  setCommonly: 'Сделать выбранное окно избранным',
  cancelCommonly: 'Отменить частое использование выбранного окна',
  export: 'Пакетный экспорт окон',
  exportChecked: 'Экспорт выбранных окон',
  exportBySeq: 'Экспорт окон с указанными номерами',
  exportByGroup: 'Экспорт окон указанной группы',
  batchEdit: 'Пакетное редактирование окон',
  updateGroupOfChecked: 'Изменить группу выбранного окна',
  updateProxyOfChecked: 'Изменить прокси-сервер выбранного окна',
  updateRemarkOfChecked: 'Изменить комментарий выбранного окна',
  updateSortOfChecked: 'Изменить настраиваемый порядковый номер выбранного окна',
  updatePropertiesOfChecked: 'Изменить все настройки выбранного окна',
  closeBrowsers: 'Пакетное закрытие окон',
  closeChecked: 'Пакетное закрытие выбранных окон',
  closeAll: 'Пакетное закрытие всех окон',
  clearCaches: 'Очистить кэш окон',
  clearCachesAll: 'Очистить кэш окон (весь)',
  share: 'Поделиться выбранным окном с пользователями',
  shareBrowser: 'Поделиться окном с пользователями',
  transform: 'Передать выбранное окно пользователю',
  transformBrowser: 'Передать окно другому пользователю',
  removeShared: 'Удалить выбранные общие окна',
  deleteBrowsers: 'Удалить выбранные окна',
  deleteBrowser: 'Удалить окно',
  deleteCompletely: 'Полностью удалить выбранные окна',
  deleteCompletelybrowser: 'Полностью удалить окно',
  moveToRecycle: 'Переместить выбранные окна в корзину',
  moveBrowserToRecycle: 'Переместить окна в корзину',
  belongTo: 'Принадлежит: ',
  noProxy: 'Прямое подключение',
  remark: 'Примечание',
  lastOpenTime: 'Последнее открытие: ',
  lastCloseTime: 'Последнее закрытие: ',
  lastUpdateTime: 'Последнее обновление: ',
  userName: 'Имя пользователя: ',
  isOpening: 'Открывается данное окно!',
  browserDisabled: 'Уменьшается количество доступных окон, окно автоматически становится недействительным',
  opening: 'Открывается',
  open: 'Открыть',
  goDown: 'Перейти вниз',
  goUp: 'Перейти вверх',
  goMiddle: 'Перейти в середину',
  editGroup: 'Изменить группу',
  editGroupWithSeq: 'Изменить группу (номер: {seq})',
  editNameWithSeq: 'Изменить имя окна (номер: {seq})',
  editNameWithSeq1: 'Изменить имя облачного телефона (номер: {seq})',
  browserName: 'Название',
  editRemark: 'Изменить комментарий',
  editRemarkWithSeq: 'Изменить комментарий (номер: {seq})',
  editRemarkType: 'Способ изменения',
  replaceRemark: 'Заменить',
  appendRemark: 'Добавить',
  appendRemarkTips: 'Примечание: Если выбрано "Добавить", то новое содержимое будет добавлено к существующему комментарию с новой строки',
  remarkContent: 'Содержание комментария',
  exportFromSeq: 'Начальный номер',
  minSeqPlaceholder: 'Введите число больше 0',
  exportToSeq: 'Конечный номер',
  maxSeqPlaceholder: 'Введите число больше начального номера',
  shareTitle: 'Поделиться окном',
  shareTitle1: 'Поделиться облачным телефоном',
  shareTips:
    'Вы можете делиться профилем с несколькими пользователями для совместного использования. Просто введите имена пользователей и нажмите кнопку "Добавить". Если после совместного использования на некоторых платформах не удается оставить комментарии, включите синхронизацию IndexedDB и синхронизацию Local Storage и попробуйте снова.',
  shareTips1: `Можно поделиться телефоном с несколькими пользователями для совместного использования. Введите имя пользователя и нажмите кнопку 'Добавить'`,
  shareWith: 'Поделиться',
  shareAdd: 'Добавить',
  shareRemark: 'Комментарий к обмену даннымиОбмен комментариями',
  transformTitle: 'Передать окно',
  transformTitle1: 'Передать облачный телефон',
  transformTips:
    'После передачи окон они полностью перейдут к пользователю, которому вы их передали, действуйте осторожно! Если после передачи на некоторых платформах не удается оставить комментарий, включите синхронизацию IndexedDB и синхронизацию Local Storage и попробуйте снова.',
  transformTips1: `Предупреждение: После передачи телефона он станет полностью собственностью получателя, и вы не сможете его увидеть. Пожалуйста, будьте осторожны!`,
  transformTo: 'Перенести пользователю',
  groupName: 'Имя группы',
  checkAll: 'Выбрать все',
  loading: 'Загрузка...',
  checkGroup: 'Выберите группу',
  ruleNameMsg: 'Название окна не может превышать 50 символов',
  ruleRemarkMsg: 'Комментарий не может превышать 500 символов',
  ruleMinSeqMsg: 'Начальный номер должен быть больше 0',
  ruleMaxSeqMsg: 'Конечный номер должен быть больше начального номера',
  usernameMinLength: 'Минимальная длина имени пользователя - 6 символов',
  usernameExists: 'Это имя пользователя уже добавлено',
  remarkMaxLength: 'Максимальная длина комментария - 100 символов',
  transformGroupMsg: 'Введите название группы, которая будет отнесена в подчинение к имени пользователя',
  noAddPermission: 'У вас нет прав на создание окон. Пожалуйста, обратитесь к администратору аккаунта, чтобы получить доступ.',
  setSuccess: 'Настройки успешно сохранены',
  notInClient: 'Установите клиентское программное обеспечение и откройте окно в нем!',
  installText: 'Установить клиент',
  getExtensionFailed: 'Ошибка при получении расширения. Проверьте подключение к сети.',
  getConfigFailed: 'Не удалось получить настройки. Попробуйте обновить страницу.',
  openAnyway:
    'Это окно браузера уже открыто в другом аккаунте. Хотите открыть его в текущем аккаунте?<p style="margin-top: 10px">Внимание: После повторного открытия окна в разных аккаунтах будет запущена синхронизация, что может привести к несогласованным данным!</p>',
  cloneMsg: 'Можно выбрать только одно окно для клонирования. Выбор нескольких окон запрещен!',
  cloneBrowser: 'Клонировать окно',
  openAnyway2: 'Это окно браузера было закрыто в другом аккаунте. Хотите открыть его в текущем аккаунте?',
  openAnyway3: 'В этот раз больше не будет предупреждений   ',
  selectBrowser: 'Выберите окно',
  noBrowserChecked: 'Окно не выбрано',
  freeAccountTips:
    'В бесплатной версии есть ограничения по данной функции. Полная функциональность доступна в платной версии. Пожалуйста, подключите/продлите платную подписку для неограниченного использования!',
  close: 'Закрыть',
  checkedIncludesOpened: 'Выбранные окна включают открытые окна. Сначала закройте окна перед выполнением операции.',
  checkedIncludesOpenedPhone: 'Выбранный облачный телефон содержит открытые окна. Сначала закройте их, а затем продолжайте.',
  checkedIncludesOpenedEnv: 'Выбранная среда содержит открытые экземпляры. Пожалуйста, закройте их перед продолжением.',
  editShareGroupMsg: 'Нельзя изменить группу общего окна.',
  editNameSuccess: 'Название окна успешно изменено',
  editRemarkSuccess: 'Комментарий успешно изменен',
  deleteConfirmMsg: 'Вы уверены, что хотите удалить выбранные окна? <br/> Удаленные окна можно восстановить из корзины!',
  moveToRecycleSuccess: 'Окно успешно перемещено в корзину',
  deleteCompletelyConfirm:
    '<p class="text-red">После удаления окна оно будет невозможно восстановить. Вы уверены, что хотите удалить окно безвозвратно?</p>',
  deleteCheckPwd:
    '<p class="text-bold q-mb-sm">Чтобы отключить проверку пароля, перейдите в <u class="cursor-pointer">Личный кабинет</u>!</p>',
  deleteCompletelySuccess: 'Окно успешно удалено',
  deleteShareConfirm: 'Вы уверены, что хотите удалить общее окно?',
  deleteShareSuccess: 'Общее окно успешно удалено',
  handleInClient: 'Операция должна быть выполнена в клиентском приложении',
  clearCachesConfirm:
    'Очистка кэша браузера приведет к полной потере данных о входе в систему и т. д. <br/> Вы уверены, что хотите очистить кэш?',
  clearCacheSuccess: 'Кэш успешно очищен',
  checkedHasClosed: 'Выбранные окна уже закрыты',
  closeBrowser: 'Окно закрыто',
  allBrowserClosed: 'Все окна успешно закрыты',
  transformSuccess: 'Окно успешно передано',
  transformSuccess1: 'Передача облачного телефона успешно выполнена',
  atleatShareOneUser: 'Заполните и добавьте хотя бы одного пользователя',
  shareSuccess: 'Удалось поделиться',
  exportConfirm: 'Вы уверены, что хотите экспортировать выбранные элементы?',
  exportFilename: 'Экспорт окна.xlsx',
  exportBrowser: 'Экспорт окна',
  browserHasOpened: ' Браузер открыт',
  exportFilenameWithSeq: 'Экспорт окна {seq}.xlsx',
  batchOpenTitle: 'Пакетное открытие браузера',
  batchOpenChecked: 'Выбранные окна:',
  batchOpened: 'Открыто:',
  batchOpenFailed: 'Открыть не удалось',
  toSetting: 'Нажмите здесь, чтобы устранить ошибку',
  batchOpening: 'Открывается...',
  batchOpenTips: 'Примечание: после закрытия всплывающего окна оставшиеся неоткрытые окна не будут открываться',
  CPU使用率: 'Коэффициент использования ЦПУ',
  内存使用率: 'Коэффициент использования памяти',
  缓存所在目录剩余空间小于: 'Ошибка при запуске окна {arg1}. Свободное место в каталоге кэша меньше {arg2}',
  extractIpFailed: 'Не удалось извлечь прокси-IP, остановка открытия',
  batchUpdateDialog: 'Пакетное обновление информации окон',
  batchUpdateImport: 'Один файл Excel. Не более 1000 окон для импорта',
  batchUpdateComment: 'Инструкции по использованию:',
  batchUpdateComments1: 'Сначала экспортируйте окна, информацию о которых нужно обновить.',
  batchUpdateComments2: 'Измените соответствующую информацию окон на основе идентификаторов, указанных в экспортированном файле.',
  batchUpdateComments3:
    'Для полей, которые вы не хотите обновлять и хотите сохранить существующие данные, такие как файлы cookie, просто заполните соответствующую ячейку значением "Skip" ("Пропустить").',
  batchUpdateComments4: 'Загрузите измененный файл Excel на сервер.',
  batchUpdateTips:
    'Примечание: не удаляйте или не изменяйте идентификаторы в файле Excel, это может привести к невозможности распознать информацию об окнах. Если вы не хотите обновлять определенные данные, не изменяйте и не удаляйте их.',
  proxyIp: 'Прокси-IP',
  createdTime: 'Создано',
  config: 'Конфигурация',
  fileImport: 'Импорт файла',
  importExcel: 'Импорт из Excel',
  downloadTemplate: 'Скачать шаблон для импорта',
  importTips: '*Поддерживается также импорт файлов с отпечатками популярных браузеров.',
  downTemplateTips: 'Подсказка',
  downTemplateTips2: 'Шаблон для импорта обновлен. Скачайте последнюю версию шаблона!',
  checkFileErrMsg: 'Пожалуйста, выберите файл',
  downloadFilename: 'Шаблон импорта окон.xlsx',
  uploadMaxSize: 'Максимальный размер файла для загрузки - 20 МБ!',
  openProgressDialog: 'Открывается браузер...',
  browserOpened: 'Окно браузера открыто',
  searchItem: 'Поиск элемента',
  setDefault: 'Установить по умолчанию',
  searchNamePlaceholder: 'Поиск по имени окна',
  searchUsernamePlaceholder: 'Введите имя пользователя для поиска',
  searchSeqPlaceholder: 'Поиск по номерам (через запятую)',
  setSeq: '№',
  customSeq: 'Пользовательская сортировка',
  seqRang: 'Номерной диапазон',
  minSeq: 'Мин. номер',
  maxSeq: 'Макс. номер',
  searchRemarkPlaceholder: 'Поиск по комментариям',
  searchRemark: 'Комментарий окна',
  searchProxyPlaceholder: 'Выберите тип прокси для поиска',
  searchHostPlaceholder: 'Введите хост прокси для поиска',
  searchBrowserStatus: 'Статус открытия окна',
  searchIdPlaceholder: 'Введите ID для поиска',
  seqRuleMsg: 'Введите число от 0 до 999999',
  seqSplitRuleMsg: 'Вводите целые числа через запятую',
  proxyRuleMsg: 'Выберите допустимый тип прокси',
  hostRuleMsg: 'Хост прокси не должен превышать 100 символов',
  ipRuleMsg: 'IP-адрес не должен превышать 50 символов',
  shareBtn: 'Управление общим доступом',
  shareBtn1: 'Управление общим доступом',
  shareDialog: 'Мои общие доступы',
  shareBrowserNamePlaceholder: 'Поиск по имени окна',
  sharePhoneNamePlaceholder: 'Поиск по имени устройства',
  shareRemarkPlaceholder: 'Поиск по комментариям',
  shareUsernamePlaceholder: 'Поиск по имени пользователя для обмена данными',
  shareHandlerUser: 'Операционный пользователь',
  shareCancel: 'Отмена обмена данными',
  shareCancelTips:
    'Вы уверены, что хотите отменить общий доступ к этому окну?<br/>После отмены общего доступа пользователи не смогут видеть это окно.',
  shareCancelTips1:
    'Вы уверены, что хотите отменить совместное использование этого облачного телефона? После отмены пользователь больше не увидит его.',
  shareCancelSuccess: 'Общий доступ успешно отменен',
  检查并同步扩展中: 'Проверка и синхронизация расширений...',
  '扩展同步失败，请尝试重新启动': 'Синхронизация расширений не удалась. Попробуйте перезапустить.',
  '可能因为网络不稳造成，请尝试在客户端的左上角切换线路后重试！':
    'Возможно, проблемы сети. Попробуйте переключить линию в левом верхнем углу клиентского приложения и повторите попытку!',
  开始获取IP: 'Начало получения IP...',
  '直连模式，网络畅通': 'Прямое подключение, сеть работает...',
  IP代理成功: 'Успешное подключение по IP...',
  网络不通已停止打开浏览器: 'Сеть недоступна. Открытие браузера прекращено...',
  正在同步指纹信息: 'Синхронизация данных отпечатков...',
  正在同步缓存配置: 'Синхронизация настроек кэша...',
  指纹同步失败: 'Синхронизация отпечатков не удалась',
  '浏览器缓存文件创建失败，请重试': 'Не удалось создать файлы кэша браузера. Попробуйте снова',
  正在打开浏览器窗口: 'Открытие окна браузера...',
  '正在更新浏览器内核，请稍后':
    'Обновление ядра браузера. После завершения обновления окно откроется автоматически! (Если скорость загрузки низкая, попробуйте отключить прокси-узел)',
  打开窗口发生异常:
    'Возникла ошибка при открытии окна: 1. Проверьте, отображается ли под заголовком приложения оранжевая предупреждающая кнопка об обновлении ядра. Обновите ядро и повторите попытку; 2. Проверьте, было ли окно открыто слишком долго (более 1 месяца). Попробуйте создать резервную копию важных данных, очистить кэш и снова открыть окно; 3. Возможно, проблема вызвана неверным системным временем. Синхронизируйте его с актуальным временем; 4. Локальная сеть работает нестабильно, переключите линию и повторите попытку, или, если включено программное обеспечение локального прокси-сервера, переключите прокси-узел или временно закройте прокси-сервер и повторите попытку; 5. Если проблему не удается решить вышеуказанными способами, обратитесь в службу поддержки!',
  内存使用率超出: 'Ошибка при запуске окна {arg1}. Превышение коэффициента использования внутренней памяти {arg2}',
  同步IndexedDB失败: 'Синхронизация IndexedDB не удалась',
  '同步Local Storage失败': 'Синхронизация Local Storage не удалась',
  同步书签失败: 'Синхронизация закладок не удалась',
  同步历史记录失败: 'Синхронизация истории не удалась',
  同步已保存的密码失败: 'Синхронизация сохраненных паролей не удалась',
  同步Google账号信息失败: 'Синхронизация данных аккаунта Google не удалась',
  代理IP无法连接: 'Не удалось подключиться к прокси-IP',
  窗口已打开: 'Окно уже открыто',
  '内核更新失败，请重新启动客户端再打开': 'Обновление ядра не удалось. Перезапустите клиент и откройте его снова.',
  正在加载代理IP: 'Загрузка прокси IP.',
  exportCookies: 'Экспорт куки (Cookie) пакетно',
  exportBrowserCookies: 'Экспорт Cookie',
  reopeningBrowser: 'Перезапустить окно',
  batchBackupCookie: 'Пакетное резервное копирование Cookie',
  batchBackupCookieTip: 'Вы собираетесь создать резервную копию Cookie из текущего выбранного окна на сервере. Желаете продолжить?',
  exportSelectedCookies: 'Экспорт Cookie выбранных окон',
  exportCookiesBySeq: 'Экспорт Cookie окон с указанным диапазоном номеров',
  exportCookiesByGroup: 'Экспорт Cookie окон указанной группы',
  exportCookiesConfirm: 'Вы уверены, что хотите экспортировать выбранные Cookie в текстовом формате?',
  importCookies: 'Пакетное обновление Cookie окон',
  importCookiesTips: 'Внимание:',
  importCookiesTips1:
    'Каждый файл Cookie браузера должен быть сохранен отдельно в формате txt с именем, соответствующим номеру окна. Например, если номер окна - 123, то имя файла должно быть 123.txt;',
  importCookiesTips2: 'Все файлы txt должны быть помещены в одну папку с названием "Cookies", а затем сжаты в архив zip;',
  importCookiesTips3:
    'Максимальное количество Cookie файлов (окон) формата txt для обновления за один раз - 100, размер архива zip не должен превышать 20 МБ;',
  importCookiesTips4:
    'После успешной загрузки Cookie окна  соответствующего номера будут заменены и обновлены на загруженные вами последние Cookie.',
  notZip: 'Выберите файл формата ZIP для загрузки.',
  importCookiesUploader: 'Архив ZIP содержит не более 100 файлов txt',
  updateCookiesSuccess: 'Cookie успешно обновлены',
  reopenAtPos: 'Пакетный перезапуск окон',
  reopenSelectedAtPos: 'Пакетный перезапуск выбранных окон',
  reopenAllAtPos: 'Пакетный перезапуск всех окон',
  reopening: 'Выполняется перезапуск окон',
  reopenFinished: 'Перезапуск окон завершен',
  reopenErrors: 'Следующие окна не были успешно перезапущены:',
  reopeningAndWait: 'Окна перезапускаются, пожалуйста, подождите...',
  nameSortTips:
    'Если имена окон используют числовое обозначение и вы хотите полностью отсортировать их в порядке возрастания чисел, начните с самого большого числа (количество знаков во всех именах окон должно быть одинаковым, например: 10000, 10001, 10099, 10100, 10101, 10110), чтобы гарантировать полную сортировку чисел. В противном случае могут возникнуть ситуации, когда числа 100 и 110 находятся между 10 и 99 и т. д.!',
  nameSortTips1:
    'Если имена устройств представлены числами и вы хотите полностью отсортировать их по возрастанию чисел, начните с самого большого числа (все числа в именах устройств должны иметь одинаковое количество цифр, например: 10000, 10001, 10099, 10100, 10101, 10110), чтобы гарантировать полную сортировку чисел. В противном случае могут возникнуть ситуации, когда числа 100 и 110 находятся между 10 и 99 и так далее!',
  restoreSuccess: 'Восстановление выполнено успешно',
  noBackupCookie: 'Cookie для данного окна не были резервированы, восстановление не требуется',
  noShareSelf: 'Нельзя производить обменым данными с самим собой. Введите другое имя пользователя',
  correctUsername: 'Введите правильное имя пользователя',
  ipChangeAttention:
    'Внимание: Если вы хотите открыть это окно браузера, перейдите в "Редактировать окно -> Общие настройки" и выключите переключатель "При изменении IP остановить открытие"',
  clearCacheWithoutExtensions: 'Очистить кэш окна (сохранить данные расширений)',
  clearCachesConfirm2: 'Эта операция удалит все файлы кэша, кроме данных расширений в окне. Вы уверены, что хотите очистить?',
  clearCloudCache: 'Удаление кэша облачного расширения.',
  clearCloudCacheConfirm:
    "После удаления кэша данных расширения в облаке, смена компьютера и вход в программу приведет к невозможности синхронизации кэша данных расширения. Также переключатель 'Синхронизация данных расширений' будет автоматически выключен, и окно закроется. Вы уверены, что хотите продолжить удаление?",
  closeAllBrowser: 'Выполните действие после закрытия окна',
  waitBatchOpenBrowser: 'Идет пакетное открытие окон, пожалуйста, подождите...',
  beginning: 'Начало ',
  contain: 'Содержит',
  precise: 'Точный',
  stopWhileCountryChange:
    'Текущий IP соответствует другой стране/региону в сравнении с последним открытием, остановка открытия браузера...',
  lastCountryName: 'Страна/регион IP при последнем открытии окна: {arg1}',
  currentCountryName: 'Страна/регион IP при текущем открытии окна: {arg1}',
  countryChangeAttention:
    "Внимание: если вы хотите открыть это окно браузера, перейдите в 'Конфигурация -> Общие настройки' и отключите переключатель 'Прекратить доступ при изменении соответствущей страны/региона IP-адреса'.",
  clientTooOld:
    'Ваша версия клиентского приложения слишком старая. Обновите до последней версии для полной функциональности и улучшенной безопасности. Ваш отпечаток будет более надежным.',
  downloadLatestClient: 'Скачать последнюю версию',
  action: 'Действие',
  transferCancelTips1:
    'Вы уверены, что хотите передать выбранный облачный телефон? После передачи он будет принадлежать получателю, и вы не сможете его видеть. Действуйте с осторожностью!',
  transferCancelTips:
    'Вы уверены, что хотите передать выбранное окно текущему пользователю, с которым вы делитесь? После передачи окно будет полностью принадлежит пользователю, которому вы передали его, и вы не сможете его видеть. Действуйте осторожно!',
  transformSelect: 'Перенести выбранное',
  sharePeople: 'Обменивающийся данными:',
  selectUserPlaceholder: 'Выберите пользователя.',
  updateConfig: 'Обновление конфигурации окна',
  coreProductTip: 'Пожалуйста, выберите окна из одного и того же браузера для массового изменения.',
  isMacOpenBrowserTips:
    'Система Mac временно не поддерживает открытие окон, созданных с использованием ядра Firefox, пожалуйста, используйте ядро Chrome.',
  repairProduct: 'Попытка восстановления',
  修复失败: 'Не удалось восстановить. Если у вас включено программное обеспечение прокси, закройте его и повторите попытку.',
  修复成功: 'Восстановление прошло успешно. Повторно откройте окно.',
  customSort: 'Пользовательская сортировка',
  editCustomSort: 'Редактировать сортировку',
  sortLabel: 'Сортировка',
  customSortBtn: 'Произвольная пользовательская сортировка',
  customSortDialogTip1:
    'Данная функция будет доступна после того, как вы поставите галочку "Пользовательская сортировка"  в "Столбце отображения пользовательских настроек"',
  customSortDialogTip2: '{msg}покроет исходное значение сортировки, невозможно восстановить, продолжить изменение?',
  sortOkBtn: 'Перейти к настройкам',
  sortRuleMsg2: 'Число сортировки должно быть от 0 до 3000000',
  specifySeq: 'Указать свой номер',
  seqMultiple: 'Номера',
  customSeqRange: 'Диапазон пользовательской сортировки',
  customSeqMsg: 'Произвольная пользовательская сортировка',
  positiveSequenceNum: 'По возрастанию',
  reverseSequenceNum: 'В порядке убывания',
  startSerialNumlabel: 'Исходный порядковый  номер',
  sequenceNumplaceholder: 'Введите порядковый номер',
  positiveSequenceTip:
    'Выбранные окна будут генерировать порядковые номера в положительном порядке в соответствии с установленным исходным порядковым номером, исходное значение сортировки будет перезаписано без возможности восстановить!',
  reverseSequenceTip:
    'Выбранные окна будут генерировать порядковые номера в обратном порядке в соответствии с установленным исходным порядковым номером, исходное значение сортировки будет перезаписано без возможности восстановить!',
  exportSelected: 'Экспортировать выбранные окна',
  verifyNow: 'Пройти верификацию',
  batchOpenComplateFlag: 'Оставить это всплывающее окно после завершения действий',
  cookieRobot: 'Cookie робот',
  cookieRobotTip1:
    'Новые созданные окна могут посчитать некоторые веб-сайты подозрительными при их открытии. Требуется определенная история просмотров. Пользователи могут использовать Cookie робота для автоматического просмотра целевого веб-сайта и создания записей о посещениях.',
  cookieRobotTip2:
    'По умолчанию в профиле открывается выбранный URL платформы, но пользователи могут настроить и добавить необходимые URL-адреса.',
  closeAfterRobotDone: 'Закрыть окно после завершения выполнения.',
  cookieRobotUrlValid: 'Укажите хотя бы один URL веб-сайта',
  delBackup: 'Удалить бэкап',
  searchIdRule: 'Пожалуйста, введите правильный ID'
}
