<template>
  <q-dialog ref="dialogRef" @hide="onDialogHide" no-backdrop-dismiss :style="outerStyle">
    <q-card class="q-dialog-plugin" :style="contentStyle">
      <q-card-section>
        <div class="flex items-center">
          <q-icon name="bit-help" :color="iconColor" size="18px"></q-icon>
          <span class="q-ml-sm" style="font-size: 14px">
            <strong>{{ $t('common.reminder') }}</strong>
          </span>
          <q-btn
            icon="o_close"
            class="absolute"
            round
            flat
            unelevated
            dense
            style="right: 10px; top: 12px; z-index: 2"
            @click="onCancelClick"
            v-if="showClose"
          ></q-btn>
        </div>
      </q-card-section>
      <q-card-section>
        <!-- 可以用换行符等 -->
        <div v-html="text" class="text-container"></div>
      </q-card-section>
      <q-card-actions class="row">
        <q-checkbox v-model="closeaction" dense :label="saveText" color="primary" v-if="saveSelect && token" />
        <q-space></q-space>
        <q-btn no-caps unelevated :style="cancelStyleVal" @click="onCancelClick" v-if="cancelBtn" :label="cancelBtnText"></q-btn>
        <q-btn no-caps :label="$t('form.minimize')" unelevated class="theme-cancel-btn" @click="minimize" v-if="showMinimize"></q-btn>
        <q-btn no-caps color="primary" unelevated @click="onOKClick" v-if="okBtn" :label="okBtnText" :style="okStyle"></q-btn>
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>

<script>
import { ref } from 'vue'
import { mapGetters } from 'vuex'
import { useDialogPluginComponent } from 'quasar'
import { setGlobalValueSetting } from 'src/api/settings'
import { CacheMap } from 'utils/cache'
import { useQuasar } from 'quasar'
export default {
  name: 'SysConfirm',
  props: {
    showClose: {
      type: Boolean,
      default: false
    },
    text: String,
    iconColor: {
      default: 'primary',
      type: String
    },
    saveSelectText: {
      type: String,
      default: ''
    },
    okText: {
      type: String,
      default: ''
    },
    cancelBtn: {
      type: Boolean,
      default: true
    },
    okBtn: {
      type: Boolean,
      default: true
    },
    cancelText: {
      type: String,
      default: ''
    },
    cancelStyle: {
      type: String,
      default: 'background: #ececec;'
    },
    okStyle: String,
    contentStyle: String,
    showMinimize: Boolean,
    outerStyle: String,
    saveSelect: Boolean
  },
  data() {
    return {
      browserBaseSetting: {}
    }
  },
  computed: {
    saveText() {
      return this.saveSelectText || this.$t('setting.rememberChoose')
    },
    okBtnText() {
      return this.okText || this.$t('form.confirm')
    },
    cancelBtnText() {
      return this.cancelText || this.$t('form.cancel')
    },
    ...mapGetters({
      token: 'user/token',
      globalvalueSetting: 'user/globalvalueSetting'
    })
  },
  watch: {
    globalvalueSetting: {
      handler(res) {
        if (res) {
          if (res.browserBaseSetting && res.browserBaseSetting.globalValue) {
            let obj = JSON.parse(res.browserBaseSetting.globalValue)
            this.browserBaseSetting = obj
          }
        }
      },
      immediate: true,
      deep: true
    }
  },

  emits: [
    // REQUIRED; need to specify some events that your
    // component will emit through useDialogPluginComponent()
    ...useDialogPluginComponent.emits
  ],

  methods: {
    async minimize() {
      // 判断有没有保存
      if (this.closeaction && this.token) {
        await setGlobalValueSetting({
          globalValueType: 'browserBaseSetting',
          globalValue: JSON.stringify({ ...this.browserBaseSetting, closeaction: 'minimize' })
        })
        CacheMap.DeleteGlobalValueCache('browserBaseSetting')
        this.$store.dispatch('user/getGlobalValueList')
      }
      this.dialogRef.hide()
      await this.$nextTick()
      if (window.isMac) {
        window.bitClientMain.minimize()
      } else {
        window.bitClientMain.hideMainWindow()
      }
    }
  },

  setup(props) {
    const $q = useQuasar()
    const cancelStyleVal = ref(props.cancelStyle)
    if (cancelStyleVal.value.includes('#ececec') && $q.dark.isActive) {
      cancelStyleVal.value = 'background: #454444;'
    }
    // REQUIRED; must be called inside of setup()
    const { dialogRef, onDialogHide, onDialogOK, onDialogCancel } = useDialogPluginComponent()
    // dialogRef      - Vue ref to be applied to QDialog
    // onDialogHide   - Function to be used as handler for @hide on QDialog
    // onDialogOK     - Function to call to settle dialog with "ok" outcome
    //                    example: onDialogOK() - no payload
    //                    example: onDialogOK({ /*.../* }) - with payload
    // onDialogCancel - Function to call to settle dialog with "cancel" outcome
    let closeaction = ref(false)
    return {
      // This is REQUIRED;
      // Need to inject these (from useDialogPluginComponent() call)
      // into the vue scope for the vue html template
      dialogRef,
      onDialogHide,
      closeaction,
      cancelStyleVal,
      // other methods that we used in our vue html template;
      // these are part of our example (so not required)
      onOKClick() {
        // on OK, it is REQUIRED to
        // call onDialogOK (with optional payload)
        onDialogOK(closeaction.value)
        // or with payload: onDialogOK({ ... })
        // ...and it will also hide the dialog automatically
      },
      // we can passthrough onDialogCancel directly
      onCancelClick: onDialogCancel
    }
  }
}
</script>

<style lang="scss" scoped>
.text-container {
  padding-left: 28px;
  overflow-wrap: break-word;
}
</style>
