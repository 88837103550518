import store from 'src/store'
import { closePhoneStatus } from 'src/api/environment'
import { closeAllPhoneBrowsers, getQunkongId, closeQunkong } from 'api/client'
import { closeSSEEvent } from './clientMessage'
export async function updatePhoneStatus() {
  const openedPhones = store.getters['client/openedPhones']
  let openPhons = Array.from(openedPhones)
  let openedQunkongIds = []
  try {
    openedQunkongIds = await getQunkongId()
  } catch (error) {}
  for (let i = 0; i < openPhons.length; i++) {
    let item = openPhons[i]
    await closePhoneStatus({
      id: item,
      isRelease: 0
    })
  }
  if (openedQunkongIds.length) {
    await closeQunkong({
      ids: openedQunkongIds
    })
  }
  if (openPhons.length) {
    closeAllPhoneBrowsers()
  }
  closeSSEEvent()
}
